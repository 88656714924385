// Here you can add other styles

.publicPageListItem {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    color: black;
}

.publicPageTableItem {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    color: black;
    text-align: left;
    background-color: #f9fafb;
}
.publicPageTableHeader {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    font-style: bold;
    background-color: #3e96db;
    color: white;
}

.publicPageTableCourseHeader {
    font-family: 'BrandonTextWeb-Regular', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0.01em;
    background-color: #c9e2f5;
    color: black;
}

.publicPageTableItemRight {
    text-align: right !important;
}

.publicPageTableItemCenter {
    text-align: center !important;
}

.publicPageTableItemLeft {
    text-align: left !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #c8e2f5 !important;
}

@media screen and (max-width: 450px) {
    .publicPageTableHeader {
        font-size: 0.55em;
    }
    .publicPageTableItem {
        font-size: 0.55rem;
    }
}


:root {
    --sx-color-on-primary-container: #fff !important;
    --sx-color-primary-container: #00a1ff !important;
    --sx-color-primary: #146899 !important; 

}